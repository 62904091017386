import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="app-content bg-light min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-primary mb-4">404</h1>
        <div className="mb-8">
          <i className="fas fa-exclamation-triangle text-6xl text-warning"></i>
        </div>
        <h3 className="font-bold mb-4 text-2xl">عذراً ، حدث خطأ مـا</h3>
        <h5 className="text-gray mb-8">
          الصفحة التي تحاول البحث عنها غير موجودة يرجى المحاولة مرة آخرى او العودة الى{" "}
          <Link to="/" className="text-primary hover:underline">
            الرئيسية
          </Link>
        </h5>
        <Link
          to="/"
          className="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary-dark transition-colors"
        >
          العودة للصفحة الرئيسية
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
